import { BillingInformation } from "../../types";
import * as styles from './BillingInfo.module.scss';
import { InputText } from "ui";

export const BillingInfo = ({ billingSummary, isReferenceFieldHidden }: { billingSummary: BillingInformation, isReferenceFieldHidden: boolean }) => {
    const translations = window.app.preloadState.translation;

    return (
        <>
            <h3 className={styles.title}>{translations["orderConfirmation.billing.Title"]}</h3>
            <div className={styles.twoColumns}>
                <div className={styles.personalInformation}>
                    {!!billingSummary.customerEmail &&
                        <InputText
                            name="emailAddress"
                            label={translations["orderConfirmation.email"]}
                            value={billingSummary.customerEmail}
                            readonly={true}
                        />}
                    {!!billingSummary.customerPhoneNumber &&
                        <InputText
                            name="phoneNo"
                            type="tel"
                            label={translations["orderConfirmation.phoneNo"]}
                            value={billingSummary.customerPhoneNumber}
                            readonly={true}
                        />}
                    {!!billingSummary.customerPurchaseOrderNumber &&
                        <InputText
                            name="orderNumber"
                            label={translations["orderConfirmation.purchaseNumber"]}
                            value={billingSummary.customerPurchaseOrderNumber}
                            readonly={true}
                        />}
                    {!isReferenceFieldHidden && !!billingSummary.customerOrderReference && 
                        <InputText
                            name="orderReference"
                            label={translations["orderConfirmation.reference"]}
                            value={billingSummary.customerOrderReference}
                            readonly={true}
                        />}
                </div>
                {(billingSummary.name || billingSummary.streetAndHouse || billingSummary.city || billingSummary.country || billingSummary.postalCode) &&
                    <div className={styles.address}>
                        <h5>{translations["orderConfirmation.billing.InvoiceAddress"]}</h5>
                        <div>{billingSummary.name}</div>
                        <div>{billingSummary.streetAndHouse}</div>
                        <div>{billingSummary.city}</div>
                        <div>{billingSummary.country}</div>
                        <div>{billingSummary.postalCode}</div>
                    </div>  
                }
            </div>
        </>
    );
}
