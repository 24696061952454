import * as styles from "../Item.module.scss";
import classnames from "classnames";
import { LineItem } from "../../types";

type RemanufacturedItemProps = {
    reman: LineItem;
    deposit: LineItem;
    sparePartUrl: string;
}
const translations = window.app.preloadState.translation;

export const RemanufacturedItem = ({ reman, deposit, sparePartUrl }: RemanufacturedItemProps) => {

    return (
        <div>
            <div>
                <RemanItem
                    item={reman}
                    sparePartUrl={sparePartUrl}
                    />
            </div>
            <div>
                <DepositItem
                    item={deposit}
                    sparePartUrl={sparePartUrl}
                    />
            </div>
        </div>
    );
}

type ItemProps = {
    item: LineItem;
    sparePartUrl: string;
};

const RemanItem = ({ item, sparePartUrl }: ItemProps) => {

    return (
        <a className={classnames(styles.wrapper, styles.remanItemsWrapper)} href={sparePartUrl}>
            <div className={styles.group}>
                <img className="objectFitContain" src={(item.imageUrl ?? "") + "?width=80"} alt={item.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <p className={styles.title}>{item.displayName}</p>
                    <p className={classnames(styles.articleNumber, "firstLetterCapital")}>
                        {item.code && <span className={styles.itemSubInfo}>{`${translations["orderConfirmation.articleNo"]}: ${item.code}`}</span>}
                    </p>
                    {item.labels?.length > 0 &&
                        <p className={classnames(styles.labels)}>
                            {item.labels.map(label =>
                                <span key={label.text} className={styles.label}>{label.text}</span>
                            )}
                        </p>
                    }
                    <p className={styles.additionalInfo}>
                        <span className={"firstLetterCapital"}>{translations["orderConfirmation.quantityFrp"]}: {item.inventory?.packageQuantity ?? 0}</span>
                        {item.unitId && <span className={"firstLetterCapital"}>{translations["orderConfirmation.unit"]}: {item.unitId}</span>}
                    </p>
                </div>
            </div>
            <div className={styles.prices}>
                {item.hasDiscount && (
                    <div className="d-md-none">
                        <p className={styles.discountedPrice}>{item.unitDiscountedPrice}</p>
                    </div>
                )}
                <div>
                    <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.price"]}:</div>
                    <p
                        className={classnames({
                            [styles.discountedPrice]: !item.hasDiscount,
                            [styles.originalPrice]: item.hasDiscount,
                            [styles.strike]: item.hasDiscount,
                        }, "d-md-none")}
                    >
                        {item.unitBasePrice}
                    </p>
                    <p
                        className={classnames(styles.sum, "d-none d-md-inline")}
                    >
                        {item.unitDiscountedPrice}
                    </p>
                </div>
                {item.hasDiscount && (
                    <div>
                        <div className="firstLetterCapital d-inline-block">{translations["orderConfirmation.discount"]}:</div>
                        <span>
                            <span className={classnames("d-none d-md-inline m-right--x3", styles.strike, styles.originalPrice)}>{item.unitBasePrice}</span>
                            <span className={classnames(styles.discount, "d-inline-block")}>{item.discountPercentageDisplay}</span>
                        </span>
                    </div>
                )}
            </div>
            <div className={styles.quantity}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.quantity"]}:</div>
                {item.quantity}
            </div>
            <div className={styles.sumWrapper}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.total"]}:</div>
                <p className={styles.sum}>{item.totalPrice}</p>
            </div>
        </a>
    );
}

const DepositItem = ({ item, sparePartUrl }: ItemProps) => {

    return (
        <a className={styles.wrapper} href={sparePartUrl}>
            <div className={styles.group}>
                <img className="objectFitContain" src={(item.imageUrl ?? "") + "?width=80"} alt={item.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <p className={styles.title}>{item.displayName}</p>
                    <p className={classnames(styles.articleNumber, "firstLetterCapital")}>
                        {item.code && <span className={styles.itemSubInfo}>{`${translations["orderConfirmation.articleNo"]}: ${item.code}`}</span>}
                    </p>
                    {item.labels?.length > 0 &&
                        <p className={classnames(styles.labels)}>
                            {item.labels.map(label =>
                                <span key={label.text} className={styles.label}>{label.text}</span>
                            )}
                        </p>
                    }
                </div>
            </div>
            <div className={styles.prices}>
                <div>
                    <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.price"]}:</div>
                    <p
                        className={classnames(styles.originalPrice, "d-md-none")}
                    >
                        {item.unitBasePrice}
                    </p>
                    <p
                        className={classnames(styles.sum, "d-none d-md-inline")}
                    >
                        {item.unitBasePrice}
                    </p>
                </div>
            </div>
            <div className={styles.quantity}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.quantity"]}:</div>
                {item.quantity}
            </div>
            <div className={styles.sumWrapper}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.total"]}:</div>
                <p className={styles.sum}>{item.totalPrice}</p>
            </div>
        </a>
    );
}