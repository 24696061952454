// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xPnfvDmAKmt0VVpmALNA{margin-top:32px;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:8px}.xPnfvDmAKmt0VVpmALNA p{color:#434B4FA6}@media(min-width: 0){.xPnfvDmAKmt0VVpmALNA p{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 400px){.xPnfvDmAKmt0VVpmALNA p{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 1366px){.xPnfvDmAKmt0VVpmALNA p{font-size:1.4rem;line-height:2.1rem}}._Yp8hoi0kIFMIsfdg2kW{display:flex;flex-direction:row;justify-content:center;gap:16px}.ZK61HTefJHzBf0ZpfG8l{cursor:pointer;width:40px;height:40px;background-position:center;background-repeat:no-repeat;background-image:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzNCAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0zMy42NjYzIDEyLjM5OTlMMjEuNjgzIDExLjM2NjZMMTYuOTk5NyAwLjMzMzI1MkwxMi4zMTYzIDExLjM4MzNMMC4zMzMwMDggMTIuMzk5OUw5LjQzMzAxIDIwLjI4MzNMNi42OTk2NyAzMS45OTk5TDE2Ljk5OTcgMjUuNzgzM0wyNy4yOTk3IDMxLjk5OTlMMjQuNTgzIDIwLjI4MzNMMzMuNjY2MyAxMi4zOTk5Wk0xNi45OTk3IDIyLjY2NjZMMTAuNzMzIDI2LjQ0OTlMMTIuMzk5NyAxOS4zMTY2TDYuODY2MzQgMTQuNTE2NkwxNC4xNjYzIDEzLjg4MzNMMTYuOTk5NyA3LjE2NjU4TDE5Ljg0OTcgMTMuODk5OUwyNy4xNDk3IDE0LjUzMzNMMjEuNjE2MyAxOS4zMzMzTDIzLjI4MyAyNi40NjY2TDE2Ljk5OTcgMjIuNjY2NloiIGZpbGw9IiM0MzRCNEYiIC8+Cjwvc3ZnPg==")}.ZK61HTefJHzBf0ZpfG8l.OETI0V0Tc2UN35qgGkoM{cursor:unset;background-image:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzNCAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjAwMDcgMjUuNzgzM0wyNy4zMDA3IDMxLjk5OTlMMjQuNTY3MyAyMC4yODMzTDMzLjY2NzMgMTIuMzk5OUwyMS42ODQgMTEuMzgzM0wxNy4wMDA3IDAuMzMzMjUyTDEyLjMxNzMgMTEuMzgzM0wwLjMzMzk4NCAxMi4zOTk5TDkuNDMzOTggMjAuMjgzM0w2LjcwMDY1IDMxLjk5OTlMMTcuMDAwNyAyNS43ODMzWiIgZmlsbD0iI0ZGRDIwMCIvPgo8L3N2Zz4K")}.AXr38XWR3y4z5W03lb0k{pointer-events:none}`, ""]);
// Exports
export var rating = `xPnfvDmAKmt0VVpmALNA`;
export var stars = `_Yp8hoi0kIFMIsfdg2kW`;
export var star = `ZK61HTefJHzBf0ZpfG8l`;
export var filled = `OETI0V0Tc2UN35qgGkoM`;
export var disabled = `AXr38XWR3y4z5W03lb0k`;
export default ___CSS_LOADER_EXPORT___;
