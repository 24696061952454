import * as styles from "./app.module.scss";
import classnames from "classnames";
import { KitBundle, Item, BillingInfo, DeliveryMethods, DeliveryOptions } from "./components";
import { ButtonResult, ButtonType, Rating, partition, popup } from "ui";
import { LineItem } from "./types";
import { RemanufacturedItem } from "./components/RemanufacturedItem";

const order = window.app.preloadState.orderConfirmation;
const translations = window.app.preloadState.translation;

function App() {
    const showFreightPopup = () => {
        popup(
            order.freightCostTitle,
            order.freightCostDescription,
            [
                { label: translations["orderConfirmation.freightCostButton"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ]
        );
    }

    const [normalItems, remanItems] = separateRemanGroups(order.lineItems);

    const sendRating = async (rate: number) => {
        const response = await fetch(`/api/order/${order.id}/rate?rate=${rate}`, {
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            method: "PUT"
        });
        if (response.status !== 204) {
            console.error(response.statusText, await response.text());
        } else {
            // Matomo
        }
    };

    const hasNoPriceItems = normalItems.some(item => item.hasNoPrice);

    return <div className={styles.wrapper}>
        <Rating value={order.rating} onSend={sendRating} />
        <h1 className={styles.title}>
            {!hasNoPriceItems ? translations["orderConfirmation.header"] : translations["orderConfirmation.rfqHeader"]}
        </h1>
        <div className={styles.headerText} dangerouslySetInnerHTML={{ __html: hasNoPriceItems ? order.rfqSubHeader : order.subHeader }}>
        </div>
        {order.lineItems.length > 0 && <div className={styles.content}>
            <div className={styles.sectionWrapper}>
                <p className={styles.heading}>{translations["orderConfirmation.parts"]}</p>
            </div>
            {normalItems.map((part) => (
                <Item
                    key={part.code}
                    part={part}
                    kitCode={null}
                    serialNumber={null}
                />
            ))}
            {remanItems.map(({ reman, deposit }) => (
                <RemanufacturedItem
                    key={reman.code}
                    reman={reman}
                    deposit={deposit}
                    sparePartUrl={reman.url}
                />
            ))}
        </div>}
        {order.kits.length > 0 && <div className={styles.content}>
            <div className={styles.sectionWrapper}>
                <p className={styles.heading}>{translations["orderConfirmation.kits"]}</p>
            </div>
            {order.kits.map((kit) => (
                <KitBundle
                    key={kit.kitCode}
                    kitBundle={kit}
                />
            ))}
        </div>}
        <div className={classnames(styles.content, styles.spacing)}>
            <div className={styles.summary}>
                <div>
                    <p>{translations["orderConfirmation.numberOfArticles"]}</p>
                    <p>{order.summary.lineItemsCount - order.summary.depositItemsCount}</p>
                </div>
                <div>
                    <p>{translations["orderConfirmation.subtotal"]}</p>
                    <p>{order.summary.listPricesTotal}</p>
                </div>
                {order.summary.depositItemsCount > 0 && <div>
                    <p>{translations["orderConfirmation.depositTotal"]}</p>
                    <p>+{order.summary.depositPricesTotal}</p>
                </div>}
                <div>
                    <p>{translations["orderConfirmation.yourDiscount"]}</p>
                    <p>-{order.summary.discountTotal}</p>
                </div>
                <div>
                    <p>{translations["orderConfirmation.totalWithoutVatAndShipping"]}</p>
                    <p>{order.summary.subTotal}</p>
                </div>
            </div>
        </div>
        <div className={classnames(styles.content, styles.spacing)}>
            <DeliveryMethods deliverySummary={order.deliverySummary} />
            {order.deliverySummary?.partialDeliveryAllowed && <DeliveryOptions deliverySummary={order.deliverySummary} />}
        </div>
        {(Object.values(order.customerInformation).filter(prop => !!prop).length > 0) &&
            <div className={classnames(styles.content, styles.spacing)}>
                <BillingInfo billingSummary={order.customerInformation} isReferenceFieldHidden={order.isReferenceFieldHidden} />
            </div>
        }
        <div className={styles.summaryContent}>
            <h2 className={styles.summaryTitle}>{translations["orderConfirmation.yourOrder"]}</h2>
            <div className={styles.totalContent}>
                <div>
                    <p>
                        {translations["orderConfirmation.total"]} <span>{order.summary.listPricesTotal}</span>
                    </p>
                </div>
                {order.summary.depositItemsCount > 0 && <div>
                    <p>
                        {translations["orderConfirmation.depositTotal"]} <span>+{order.summary.depositPricesTotal}</span>
                    </p>
                </div>}
                <div>
                    <p>
                        {translations["orderConfirmation.vat"]} <span>{order.summary.taxTotal}</span>
                    </p>
                </div>
                <div>
                    <p>
                        {translations["orderConfirmation.yourDiscount"]} <span>-{order.summary.discountTotal}</span>
                    </p>
                </div>
                <div className={styles.total}>
                    <p>
                        {translations["orderConfirmation.totalWithoutShipping"]}
                        <span>{order.summary.total}</span>
                    </p>
                </div>
                {order.deliverySummary?.methodType !== 1 &&
                    <div>
                        <p>
                            <span className={styles.freightCost} onClick={showFreightPopup}>
                                {translations["orderConfirmation.shipping"]}
                            </span>
                        </p>
                    </div>
                }
            </div>
            <div className={styles.acceptance}>
                <div>
                    <a className="btn btn--primary" href={order.myOrdersLink}>{translations["orderConfirmation.goToMyOrders"]}</a>
                    <a className="btn btn--outlined m-left--x3" href={order.startPageLink}>{translations["orderConfirmation.backToStartPage"]}</a>
                </div>
                <a className="btn btn--primary" href={order.customerSupportLink}>{translations["orderConfirmation.goToCustomerSupport"]}</a>
            </div>
        </div>
    </div>
}

export default App;

function separateRemanGroups(lineItems: LineItem[]): [LineItem[], { reman: LineItem, deposit: LineItem }[]] {
    const [remanItems, otherItems] = partition(lineItems, item => item.isRemanItem);

    const depositCodesToReman = new Map(remanItems.map(item => [getDepositCode(item.code), item]));

    const [depositItems, normalItems] = partition(otherItems, item => depositCodesToReman.has(item.code));

    const remanDepositPairs = depositItems.map(deposit => ({ reman: depositCodesToReman.get(deposit.code)!, deposit }));

    return [
        normalItems,
        remanDepositPairs
    ]
}

function getDepositCode(remanCode: string): string {
    return remanCode.replace("VOE90", "VOE80");
}