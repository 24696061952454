import cn from 'classnames';
import * as styles from "./KitBundle.module.scss";
import { Kit } from "../../types";
import { Item } from "../Item/Item";

export const KitBundle = ({ kitBundle }: { kitBundle: Kit }) => {
    const translationState = window.app.preloadState.translation;
    return <div className={styles.serviceKitBundle}>
        <div className={styles.wrapper}>
            <div className={styles.group}>
                <img onClick={() => location.assign(kitBundle.kitUrl ?? "")} className={cn("objectFitContain cursor-pointer")} src={kitBundle.imageUrl + "?width=160"} alt={kitBundle.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <a className={cn(styles.title, "text-decoration-none body--m")} href={kitBundle.kitUrl ?? ""}>{kitBundle.displayName}</a>
                    <p className={cn(styles.kitCode, "firstLetterCapital")}>
                        {kitBundle.kitCode && `${translationState["orderConfirmation.kitCode"]}: ${kitBundle.kitCode}`}{" "}
                    </p>
                    <p className={styles.additionalInfo}>
                        {<span className={"d-inline-block firstLetterCapital"}>{translationState["orderConfirmation.kitContains"]}: {kitBundle.kitContains}</span>}
                    </p>
                </div>
            </div>
        </div>
        {kitBundle.kitLineItems.map(kitLineItem =>
            <Item
                key={kitLineItem.lineItem.code}
                part={kitLineItem.lineItem}
                kitCode={kitLineItem.kitCode}
                serialNumber={kitLineItem.serialNumber}
            />)}
    </div>
};
