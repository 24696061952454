// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kyMJzZV1tq_fukLedT3J{border-bottom:1px solid #434B4F26;box-shadow:0px 1px 0px #fff}@media(min-width: 0){.kyMJzZV1tq_fukLedT3J{font-size:2rem;line-height:2.4rem}}@media(min-width: 400px){.kyMJzZV1tq_fukLedT3J{font-size:2rem;line-height:2.4rem}}@media(min-width: 768px){.kyMJzZV1tq_fukLedT3J{font-size:2.6rem;line-height:2.8rem}}@media(min-width: 1366px){.kyMJzZV1tq_fukLedT3J{font-size:2.6rem;line-height:2.8rem}}.kyMJzZV1tq_fukLedT3J{color:#434b4f;padding-bottom:24px;margin-bottom:24px}.tJfAp5VTgzZRZV3ow_t3{color:#434b4f}@media(min-width: 0){.tJfAp5VTgzZRZV3ow_t3{padding:0 0 18px 6px}}@media(min-width: 400px){.tJfAp5VTgzZRZV3ow_t3{padding:0 0 18px 6px}}@media(min-width: 768px){.tJfAp5VTgzZRZV3ow_t3{padding:0 0 21px 7px}}@media(min-width: 0){.tJfAp5VTgzZRZV3ow_t3{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 400px){.tJfAp5VTgzZRZV3ow_t3{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 768px){.tJfAp5VTgzZRZV3ow_t3{font-size:1.8rem;line-height:2.8rem}}@media(min-width: 1366px){.tJfAp5VTgzZRZV3ow_t3{font-size:1.9rem;line-height:2.8rem}}@media(min-width: 0){.jzkbssZ3hCsryW2iMu5w{padding:0 0 18px 0}}@media(min-width: 400px){.jzkbssZ3hCsryW2iMu5w{padding:0 0 18px 0}}@media(min-width: 768px){.jzkbssZ3hCsryW2iMu5w{padding:0 0 21px 0}}.dR5prxewkEs4tzVIPm4U{color:#434B4FA6}@media(min-width: 0){.dR5prxewkEs4tzVIPm4U{padding:0 0 18px 0}}@media(min-width: 400px){.dR5prxewkEs4tzVIPm4U{padding:0 0 18px 0}}@media(min-width: 768px){.dR5prxewkEs4tzVIPm4U{padding:0 0 21px 0}}`, ""]);
// Exports
export var title = `kyMJzZV1tq_fukLedT3J`;
export var deliveryMean = `tJfAp5VTgzZRZV3ow_t3`;
export var deliveryInput = `jzkbssZ3hCsryW2iMu5w`;
export var deliveryDescription = `dR5prxewkEs4tzVIPm4U`;
export default ___CSS_LOADER_EXPORT___;
